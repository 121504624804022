/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { RiArrowRightSLine, RiCheckLine } from "react-icons/ri"

//Images and Icons
import pkw from "../../static/assets/icons/pkw.svg"
import exoten from "../../static/assets/icons/exoten.svg"
import pickup from "../../static/assets/icons/pickup.svg"
import liebhaber from "../../static/assets/icons/liebhaber.svg"
import lkw from "../../static/assets/icons/lkw.svg"
import motorraeder from "../../static/assets/icons/motorraeder.svg"
import traktoren from "../../static/assets/icons/traktoren.svg"

import Layout from "../components/layout"
import SEO from "../components/seo"

export const pageQuery = graphql`
  query HomeQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 140)
      frontmatter {
        title
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

const HomePage = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter } = markdownRemark

  return (
    <Layout>
      <SEO
        title={frontmatter.title}
        description="Belmot Oldtimerversicherung - der beste Schutz für Ihren Klassiker. Versichert gegen alle Risiken."
      />
      <div className="home__hero">
        <div className="home__hero--content">
          <h1 className="title">
            Oldtimer versichern, <br />
            <span>aber richtig!</span>
          </h1>
          <p className="tagline">
            Mit Ihrer BELMOT<sup>&reg;</sup> Markenagentur.
          </p>
          <div>
            <Link to="/formular" className="button">
              Jetzt kostenlos anfragen
              <span class="icon -right">
                <RiArrowRightSLine />
              </span>
            </Link>
          </div>
        </div>
        <div className="home__hero--image">
          <StaticImage
            src="../../static/assets/hero_image.jpg"
            alt="hero-image"
            className="featured-image"
            layout="fullWidth"
            quality="100"
          />
        </div>
      </div>
      <div className="grid-container">
        <div className="home__intro">
          <h2>
            BELMOT<sup>&reg;</sup> – Der beste Schutz für Ihren Klassiker
          </h2>
          <p>
            Ob Käfer, Spider oder Pagode – Sie haben sich Ihren Traum
            verwirklicht und parken einen begehrten Oldtimer in Ihrer Garage.
            Damit Ihr Oldtimer im Schadenfall nicht unversichert dasteht, ist
            eine Versicherung, die dem hohen Anspruch von Oldtimer-Besitzern
            zusagt, ein Muss. Genau das bekommen sie mit BELMOT<sup>&reg;</sup>,
            der Versicherung für Oldtimer und Liebhaberfahrzeuge.
          </p>
        </div>
      </div>
      <div className="home__darksection">
        <div className="grid-container">
          <div className="home__darksection--intro">
            <h2>
              Versicherung für Ihren
              <span> Old- und Youngtimer</span>
            </h2>
            <h3>Flexible Individuallösungen statt Standardprodukte</h3>
            <div className="grids col-1 sm-2">
              <p>
                Standard-Lösungen reichen für hochwertige und sehr individuelle
                Fahrzeuge, wie beispielsweise Oldtimer oder Youngtimer nicht
                aus. Klassische Fahrzeuge benötigen neben einer guten Pflege
                auch einen optimalen Versicherungsschutz.
              </p>
              <p>
                Während andere Versicherungen meist nur Standardprodukte im
                Programm haben, bietet Ihnen die Belmot Oldtimerversicherung
                Individuallösungen an, die exakt auf die Bedürfnisse Ihres
                Fahrzeuges zugeschnitten sind.
              </p>
              <p>
                Zusammenarbeit mit diversen Oldtimerclubs zu Sonderkondition,
                wie ASC, SL-Club, Porsche Classic Card, Porsche 356 Club und
                viele weitere.
              </p>
            </div>
          </div>
          <div className="head-with-lines">
            <h3>
              <span className="head-with-lines--left"></span>
              <span className="head-with-lines--head">
                Die&nbsp;Vorteile&nbsp;von&nbsp;BELMOT<sup>&reg;</sup>
              </span>
              <span className="head-with-lines--right"></span>
            </h3>
          </div>
          <div className="home__darksection--benefits grids col-1 sm-2 lg-3">
            <div className="home__darksection--benefits--item">
              <p>
                <RiCheckLine />
              </p>
              <p>Allgefahren-Deckung</p>
            </div>
            <div className="home__darksection--benefits--item">
              <p>
                <RiCheckLine />
              </p>
              <p>Fuhrpark ab 2 Fahrzeugen</p>
            </div>
            <div className="home__darksection--benefits--item">
              <p>
                <RiCheckLine />
              </p>
              <p>Fehlbedienung mitversichert</p>
            </div>
            <div className="home__darksection--benefits--item">
              <p>
                <RiCheckLine />
              </p>
              <p>Beweislastumkehr (kein Problem im Schadenfall)</p>
            </div>
            <div className="home__darksection--benefits--item">
              <p>
                <RiCheckLine />
              </p>
              <p>Restaurationsversicherung</p>
            </div>
            <div className="home__darksection--benefits--item">
              <p>
                <RiCheckLine />
              </p>
              <p>Rahmenvereinbarung mit diversen Oldtimer Clubs</p>
            </div>
            <div className="home__darksection--benefits--item">
              <p>
                <RiCheckLine />
              </p>
              <p>Versicherung zum Wiederbeschaffungswert möglich</p>
            </div>
            <div className="home__darksection--benefits--item">
              <p>
                <RiCheckLine />
              </p>
              <p>Keine Beitragserhöhung im Schadensfall</p>
            </div>
            <div className="home__darksection--benefits--item">
              <p>
                <RiCheckLine />
              </p>
              <p>30-Prozent Vorsorge</p>
            </div>
          </div>
          <div className="home__darksection--button">
            <Link to="/belmot" className="button button--secondary">
              BELMOT im Detail
              <span class="icon -right">
                <RiArrowRightSLine />
              </span>
            </Link>
          </div>
          <div className="head-with-lines">
            <h3>
              <span className="head-with-lines--left"></span>
              <span className="head-with-lines--head">
                BELMOT<sup>&reg;</sup>&nbsp;gibt&nbsp;es&nbsp;für
              </span>
              <span className="head-with-lines--right"></span>
            </h3>
          </div>
          <div className="home__darksection--cars">
            <div className="home__darksection--cars--item">
              <img src={pkw} alt="Logo" />
              <p>Oldtimer PKW</p>
              <span>1989 und älter</span>
            </div>
            <div className="home__darksection--cars--item">
              <p>
                <img src={liebhaber} alt="Logo" />
              </p>
              <p>Liebhaberfahrzeuge</p>
              <span>von 1990 bis 1999</span>
            </div>
            <div className="home__darksection--cars--item">
              <p>
                <img src={exoten} alt="Logo" />
              </p>
              <p>Exoten </p>
              <span>ab 2000</span>
            </div>
            <div className="home__darksection--cars--item">
              <p>
                <img src={traktoren} alt="Logo" />
              </p>
              <p>Oldtimer-Traktoren</p>
              <span>1980 und älter</span>
            </div>
            <div className="home__darksection--cars--item">
              <p>
                <img src={lkw} alt="Logo" />
              </p>
              <p>Oldtimer-LKW & -Feuerwehr</p>
              <span>1980 und älter</span>
            </div>
            <div className="home__darksection--cars--item">
              <p>
                <img src={pickup} alt="Logo" />
              </p>
              <p>Oldtimer-Pickup</p>
              <span>1989 und älter</span>
            </div>
            <div className="home__darksection--cars--item">
              <p>
                <img src={motorraeder} alt="Logo" />
              </p>
              <p>Oldtimer-Motorräder</p>
              <span>1999 und älter</span>
            </div>
          </div>
        </div>
      </div>
      <div className="grid-container">
        <div className="home__anfragebanner">
          <h3>Angebot anfragen</h3>
          <p>
            Füllen Sie das Online-Anfrageformular aus, um schnell und mühelos
            ein auf Sie zugeschnittenes Angebot zu erhalten.
          </p>
          <Link to="/formular" className="button">
            Jetzt kostenfrei anfragen
            <span class="icon -right">
              <RiArrowRightSLine />
            </span>
          </Link>
        </div>
      </div>
      <div className="grid-container">
        <div className="home__banner grids col-1 sm-2">
          <div>
            <h3>
              Rundum versorgt mit der BELMOT<sup>&reg;</sup>
              -Oldtimerversicherung
            </h3>
            <p>
              Individuallösungen statt Versicherungen von der Stange – das ist
              unsere Philosophie. BELMOT<sup>&reg;</sup> erfüllt diesen
              Anspruch. Freuen Sie sich auf viele weitere Kilometer mit ihrem
              Schmuckstück.
            </p>
          </div>
          <StaticImage
            src="../../static/assets/image_1.jpg"
            alt="image"
            layout="fullWidth"
            quality="100"
            className="home__banner--img"
          />
        </div>
        <div className="home__banner grids col-1 sm-2">
          <StaticImage
            src="../../static/assets/image_2.jpg"
            alt="image"
            layout="fullWidth"
            quality="100"
            className="home__banner--img"
          />
          <div>
            <h3>Youngtimer und Liebhaberfahrzeuge sind willkommen</h3>
            <p>
              BELMOT<sup>&reg;</sup> ist eine Spezialversicherung, die sich mehr
              an Ihrer Leidenschaft orientiert als an Zulassungsdaten.
            </p>
            <p>
              Liebhaberfahrzeuge ab Baujahr 2000 und jünger sind ebenfalls mit
              BELMOT<sup>&reg;</sup> versicherbar, z. B. im Rahmen einer
              Sammlung oder bei besonders seltenen Liebhaberstücken.
            </p>
          </div>
        </div>
        <div className="home__banner grids col-1 sm-2">
          <div>
            <h3>
              Das Besondere: Versicherung zum Wiederbeschaffungswert möglich
            </h3>
            <p>
              Insider wissen: Nur eine Versicherung zum Wiederbeschaffungswert
              deckt die anfallenden Mehrkosten für eine kurzfristige Beschaffung
              ab.
            </p>
            <p>
              Sie haben ein einzigartiges, wiederaufgebautes Fahrzeug? Dann
              können wir auch den Wiederaufbauwert versichern.
            </p>
            <p>Auch Clubkonditionen möglich, wie z.B. Porsche Classic Card.</p>
          </div>
          <StaticImage
            src="../../static/assets/image_1.jpg"
            alt="image"
            layout="fullWidth"
            quality="100"
            className="home__banner--img"
          />
        </div>
        <div className="home__banner grids col-1 sm-2">
          <StaticImage
            src="../../static/assets/image_3.jpg"
            alt="image"
            layout="fullWidth"
            quality="100"
            className="home__banner--img"
          />
          <div>
            <h3>Unser schönstes Ziel: den Traum vom Sportwagen zu bewahren.</h3>
            <p>
              Ihr Oldtimer ist ein wertvolles Schmuckstück, das nicht nur Ihr
              Herz höherschlagen lässt.
            </p>
            <p>
              Damit diese Begeisterung auch in Zukunft anhält, bieten wir Ihnen
              im Rahmen der Porsche Classic Card eine Zusammenarbeit mit der
              AllRisk Belmot Oldtimerversicherung an.
            </p>
            <p>
              Die{" "}
              <strong>
                Porsche Classic Card und die Belmot AllRisk Oldtimerversicherung{" "}
              </strong>{" "}
              bietet Ihnen neben einer Mobilitätsgarantie und einen einmaligen
              Versicherungsschutz den Zugang zu exklusiven Vorteilen, Card
              Specials und Services.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default HomePage
